<template>
  <div>
    <div class="field">
      <div style="margin-bottom:15px;display:flex;justify-content:space-between;align-items:center;" v-if="!item.content[landing.current_lang][9].styleSettings">
        <label class="label">Add weather to your page</label>
      </div>
      <div style="margin-bottom:15px;" v-if="item.content[landing.current_lang][9].styleSettings">
        <v-btn outlined small color="primary" @click="item.content[landing.current_lang][9].styleSettings = false">
          <v-icon>mdi-chevron-left</v-icon> Back to weather settings
        </v-btn>
      </div>
      <div v-if="item.content[landing.current_lang][9].styleSettings">
        <label class="label">Change weather styles</label><br />
        <label>Change Icon Types</label>
        <v-radio-group style="margin-top:15px;" row v-model="content.icon_type">
          <v-radio
            v-for="type in weatherIconTypes"
            :label="type.label"
            :value="type.value"
            :key="type.id"
            style="margin-left: 15px;"
          >
          </v-radio>
        </v-radio-group>
        <label class="label">
          Change Font Family
        </label>
        <v-select
          flat
          dense
          outlined
          hide-details
          placeholder="Select Font Family"
          :items="fontFamilyTypes"
          item-text="label"
          item-value="value"
          class="canvas__select"
          v-model="content.font"
        >
          <template v-slot:selection="{ item }">
            <div :style="`font-family:'${item.value}',sans-serif`">
              {{ item.label }}
            </div>
          </template>
          <template v-slot:item="{ item }">
            <div :style="`font-family:'${item.value}',sans-serif`">
              {{ item.label }}
            </div>
          </template>
        </v-select>
        <div style="display: flex;align-items: center;margin:10px 0;">
          <label
            class="label"
            style="width:250px;margin-bottom: 0;margin-right: 15px;"
            >Text color</label
          >
          <v-menu
            top
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="showTextColor"
          >
            <template v-slot:activator="{ on }">
              <div
                class="colorpicker"
                :style="`background-color:${content.textColor}`"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                  v-model="content.textColor"
                  @input="showTextColor = false"
                  flat
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div
          v-if="content.icon_type === 1"
          style="display: flex;align-items: center;margin:10px 0;"
        >
          <label
            class="label"
            style="width:250px;margin-bottom: 0;margin-right: 15px;"
            >Icon color</label
          >
          <v-menu
            top
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="showIconColor"
          >
            <template v-slot:activator="{ on }">
              <div
                class="colorpicker"
                :style="`background-color:${content.iconColor}`"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                  v-model="content.iconColor"
                  @input="showIconColor = false"
                  flat
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div>
          <label class="label"
            >Change main icon size
            <strong>{{ content.mainIconSize }} px</strong></label
          >
          <div class="slider">
            <v-slider
              :max="300"
              :min="50"
              v-model="content.mainIconSize"
            ></v-slider>
          </div>
        </div>
        <div>
          <label class="label"
            >Change week icon size
            <strong>{{ content.weekIconSize }} px</strong></label
          >
          <div class="slider">
            <v-slider
              :max="100"
              :min="10"
              v-model="content.weekIconSize"
            ></v-slider>
          </div>
        </div>
      </div>
      <div v-if="!item.content[landing.current_lang][9].styleSettings">
        <label class="label"
          >You can change weather city
          <span style="color:#007db5;cursor:pointer;" @click="goToWeather"
            >here</span
          ></label
        ><br />
        <label class="label">Change top text</label>
        <div class="mb-4">
          <editor
            v-model="content.top_content"
            api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
            :init="options"
          />
        </div>
        <label class="label">Change bottom text</label>
        <div class="mb-4">
          <editor
            v-model="content.bottom_content"
            api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
            :init="options"
          />
        </div>
        <SliderMenu :slider_menu="slider_menu" :active_slider="active_slider" :menu="menu" />
      </div>
    </div>
    <PopupConfirm
        ref="PopupConfirm"
        @confirm="confirmCopy"
        :title="
        `Are you sure about copy content from ${defaultLangName} to ${currentLangName}`
      "
    />
  </div>
</template>

<script>

import { mapState } from "vuex";
import SliderMenu from "./SliderMenu";
import PopupConfirm from "@/components/Popup/PopupConfirm";
export default {
  name: "typeWeather",
  components: {
    SliderMenu,
    PopupConfirm,
  },
  props: ["item","content","slider_menu","active_slider","menu"],
  data() {
    return {
      fontFamilyTypes: [
        { id: 0, value: "Arial", label: "Arial" },
        { id: 1, value: "SF UI Text", label: "SF UI Text" },
        { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
        { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
        { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
        { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
        { id: 6, value: "Geometria", label: "Geometria" },
        { id: 7, value: "DIN Pro", label: "DIN Pro" },
        { id: 8, value: "Circe", label: "Circe" },
        { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
        { id: 10, value: "Acrom", label: "Acrom" },
        { id: 11, value: "Inter", label: "Inter" },
      ],
      settings: false,
      showTextColor: false,
      showIconColor: false,
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 400,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_rel',
                                  label: 'Rel',
                                  items: [
                                      {text: 'No value', value: ''},
                                      {text: 'Alternate', value: 'alternate'},
                                      {text: 'Help', value: 'help'},
                                      {text: 'Manifest', value: 'manifest'},
                                      {text: 'No follow', value: 'nofollow'},
                                      {text: 'No opener', value: 'noopener'},
                                      {text: 'No referrer', value: 'noreferrer'},
                                      {text: 'Opener', value: 'opener'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
      weatherIconTypes: [
        { id: 0, value: 0, label: "Default" },
        { id: 1, value: 1, label: "Owfont" },
        { id: 2, value: 2, label: "WuIcons" },
      ],
    };
  },
  computed: {
    ...mapState(["landing"]),
    currentLangName() {
      return this.landing.advanced.langs.find(
          (x) => x.value === this.landing.current_lang
      ).name;
    },
    defaultLangName() {
      return this.landing.advanced.langs.find(
          (x) => x.value === this.landing.advanced.default_lang
      ).name;
    },
  },
  mounted() {
    console.log(this.content);
  },
  methods: {
    copyLang(){
      this.$refs.PopupConfirm.open();
    },
    confirmCopy(){
      this.item.content[this.landing.current_lang][9] = JSON.parse(JSON.stringify(this.item.content[this.landing.advanced.default_lang][9]));
      this.forceRerender();
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.$store.state.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.$store.state.renderComponent = true;
      });
    },
    setSettings() {
      this.settings = !this.settings;
    },
    goToWeather() {
      this.$store.state.tab = 5;
      this.$store.commit("setPage", "advanced");
        this.landing.advanced.tab = 6;
    },
  },
};
</script>

<style lang="scss" scoped></style>
